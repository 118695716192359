
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

body {
    /*background-color: #F6F6F6;*/
    background-color: #eaebeb;
    margin: 0 auto;
    /*font-family: "Montserrat", sans-serif;*/
    min-width: 320px;
    max-width: 1200px;
}
